import { Loader } from '@/components/layout'
import Login from '@/components/shared/Login'
import { useAuthContext, useCurrentUser } from '@/hooks'

const LoginPage = () => {
  const { jwt } = useAuthContext()

  const { shouldCompleteSignUp } = useCurrentUser()

  if (!jwt && !shouldCompleteSignUp) {
    return (
      <div className="rounded-lg border border-default-100 bg-content1/50 p-10">
        <Login />
      </div>
    )
  }

  return <Loader />
}

LoginPage.displayName = 'LoginPage'

export default LoginPage
