import { useEffect, useState } from 'react'
import { Auth } from '@supabase/auth-ui-react'

import { cn } from '@/utils'
import { supabaseComponentClient } from '@/plugins/supabase'

export default function Login({ className }: { className?: string }) {
  const [providerRedirectTo, setProviderRedirectTo] = useState<string>()

  useEffect(() => {
    setProviderRedirectTo(window.location.origin)
  }, [])

  if (!providerRedirectTo) return null

  return (
    <div
      className={cn('flex h-full w-96 flex-col justify-center p-10', className)}
    >
      <h1 className="text-center">Sign in to get started</h1>
      <Auth
        magicLink
        providers={['google']}
        supabaseClient={supabaseComponentClient}
        redirectTo={providerRedirectTo}
      />
    </div>
  )
}
